body, html {
  height: 100%;
  margin: 20px;
}

.app-container {
  background-color: var(--db-canvas, #fae);
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: inline-block;
  position: relative;
}

.stable-container {
  position: relative;
}

.top-controls {
  justify-content: space-around;
  align-items: center;
  gap: 25px;
  display: flex;
}

.bottom-controls {
  justify-content: space-around;
  align-items: center;
  gap: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.knob1-container, .knob2-container, .knob3-container, .knob4-container {
  flex-direction: column;
  align-items: center;
  gap: 5px;
  display: flex;
}

.metro-feedback-container, .playback-container {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  display: flex;
}

.piano-roll {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  display: flex;
}

#pianoRollContainer {
  border: 1px solid #ccc;
  width: 600px;
  height: 300px;
}

.knob {
  background-color: #eeeeeefe;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.knob-value, .recIndicator {
  color: #817f7fe3;
  margin-top: 5px;
  font-family: Roboto, monospace;
  font-size: 14px;
  font-weight: normal;
}

.control-btn img {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
}

.light-base {
  vertical-align: middle;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: background-color 10ms;
  display: inline-block;
}

.light-off {
  background-color: #f987dccb;
}

.light-on {
  background-color: #ff00bf;
}

.recording {
  color: red;
}

:root {
  --switch-scale: .5;
}

.switch {
  width: calc(60px * var(--switch-scale));
  height: calc(34px * var(--switch-scale));
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  height: calc(26px * var(--switch-scale));
  width: calc(26px * var(--switch-scale));
  left: calc(4px * var(--switch-scale));
  bottom: calc(4px * var(--switch-scale));
  background-color: #fff;
  transition: all .4s;
  position: absolute;
}

input:checked + .slider {
  background-color: #21d227;
}

input:focus + .slider {
  box-shadow: 0 0 1px #21d227;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(26px * var(--switch-scale)));
  -ms-transform: translateX(calc(26px * var(--switch-scale)));
  transform: translateX(calc(26px * var(--switch-scale)));
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.arrayList-container, .reset-button {
  width: -moz-fit-content;
  width: fit-content;
  max-height: 150px;
  margin-bottom: 1px;
  display: none;
  overflow-y: scroll;
}

.midiOutputList {
  color: #817f7f;
  font-family: Roboto, monospace;
  font-size: 14px;
  font-weight: normal;
  display: none;
}

#notificationContainer {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error_notification {
  color: #fff;
  background-color: #e736f4bd;
  border-radius: 15px;
  max-width: 270px;
  max-height: 200px;
  margin-bottom: 20px;
  padding: 14px;
  font-family: Roboto, monospace;
}

#helpToggle {
  width: 1.1em;
  height: 1.1em;
}

#helpToggleText {
  color: #5d5d5de1;
  align-items: bottom;
  cursor: pointer;
  font-family: Roboto, monospace;
  font-size: 21px;
  top: 20px;
  right: 20px;
}

.notification {
  color: #fff;
  z-index: 100;
  background-color: #e736f4bd;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 250px;
  padding: 10px;
  font-family: Roboto, monospace;
  display: none;
  position: absolute;
  box-shadow: 0 2px 4px #0003;
}

.notification_tapping {
  color: #fff;
  text-align: center;
  z-index: 100;
  background-color: #4caf50;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: Roboto, monospace;
  display: none;
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
}

.help-overlay {
  z-index: 1000;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.help-content {
  color: #fff;
  background: #e736f48a;
  border-radius: 15px;
  width: 300px;
  max-height: 80%;
  padding: 20px;
  font-family: Roboto, monospace;
  position: absolute;
  overflow: auto;
  box-shadow: 0 2px 4px #0003;
}

.help-nav {
  background: #e497eaae;
  border-radius: 15px;
  justify-content: center;
  gap: 5px;
  padding: 10px 0;
  display: flex;
  position: sticky;
  bottom: 0;
}

.help-button {
  font-family: Roboto, monospace;
}

.help-dots-container {
  justify-content: center;
  padding: 10px;
  display: flex;
}

.help-dot {
  cursor: pointer;
  background-color: #bbb;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  display: inline-block;
}

.help-dot.active {
  background-color: #717171;
}

#invisibleAnchor {
  display: none;
}

#invisibleAnchor_pRoll {
  visibility: hidden;
  width: 0;
  height: 0;
}

@keyframes blink {
  0%, 100% {
    background-color: #0000;
  }

  50% {
    background-color: #ff0;
  }
}

.light-base.blink {
  animation: .2s linear blink;
}

.theme-switch-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.theme-switch {
  width: calc(60px * var(--switch-scale));
  height: calc(34px * var(--switch-scale));
  display: inline-block;
  position: absolute;
  top: 30px;
  right: -50px;
}

.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

#themeToggleText {
  color: #5d5d5de1;
  align-items: bottom;
  cursor: pointer;
  font-family: Roboto, monospace;
  font-size: 17px;
  display: inline-block;
  position: absolute;
  top: -1px;
  right: -55px;
}

.themeSlider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.themeSlider:before {
  content: "";
  height: calc(26px * var(--switch-scale));
  width: calc(26px * var(--switch-scale));
  left: calc(4px * var(--switch-scale));
  bottom: calc(4px * var(--switch-scale));
  background-color: #fff;
  transition: all .4s;
  position: absolute;
}

input:checked + .themeSlider {
  background-color: #00000024;
}

input:checked + .themeSlider:before {
  -webkit-transform: translateX(calc(26px * var(--switch-scale)));
  -ms-transform: translateX(calc(26px * var(--switch-scale)));
  transform: translateX(calc(26px * var(--switch-scale)));
}

.themeSlider.round {
  border-radius: 34px;
}

.themeSlider.round:before {
  border-radius: 50%;
}

.dark-theme {
  --background-color: #333;
  --text-color: #fff;
  --db-canvas: #b39ddb;
}

body {
  background-color: var(--background-color, #fff);
  color: var(--text-color, #000);
  transition: background-color .3s, color .3s;
}

/*# sourceMappingURL=index.73c4b606.css.map */
